<template>
  <div class="about row">
    <vxe-grid ref="xGrid" v-bind="gridOptions">
      <template #option_btn="{ row }">
        <div class="btn-group" role="group" aria-label="Basic mixed styles example">
          <button type="button" @click="edit(row)" class="btn btn-success">
            <i class="fa-solid fa-pen-to-square"></i>
          </button>
          <button type="button" class="btn btn-warning d-none">Middle</button>
          <button type="button" @click="removeOne(row)" class="btn btn-danger">
            <i class="fa-solid fa-trash-can"></i>
          </button>
        </div>
      </template>
      <template #status_switch="{ row }">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" role="switch" :data-id="row.id" :checked="row.status"
            @change="change($event, row)" />
        </div>
      </template>
      <template #toolbar_buttons>
        <vxe-button @click="add" status="success">新增</vxe-button>
        <vxe-button @click="removeMany()">删除</vxe-button>
      </template>
    </vxe-grid>

    <vxe-modal v-model="showEdit" :title="selectRow ? '编辑&保存' : '新增&保存'" width="545" min-width="300" min-height="300"
      :loading="loadding" :resize="true" destroy-on-close>
      <template #default>
        <vxe-form :data="formData" :rules="formRules" title-align="right" @submit="submitEvent">
          <vxe-form-item title="Basic information" title-align="left" :span="24"
            :title-prefix="{ icon: 'vxe-icon-comment' }"></vxe-form-item>
          <vxe-form-item field="name" title="名&nbsp;称" :item-render="{}">
            <template #default="{ data }">
              <vxe-input v-model="data.name" placeholder="请输入路由名称"></vxe-input>
            </template>
          </vxe-form-item>
          <vxe-form-item field="controller" title="模&nbsp;块" :item-render="{}">
            <template #default="{ data }">
              <vxe-input v-model="data.controller" placeholder="请输入模块名"></vxe-input>
            </template>
          </vxe-form-item>
          <vxe-form-item field="method" title="函&nbsp;数" :item-render="{}">
            <template #default="{ data }">
              <vxe-input v-model="data.method" placeholder="请输入函数名称"></vxe-input>
            </template>
          </vxe-form-item>
          <vxe-form-item field="path" title="&nbsp;路&nbsp;&nbsp;由&nbsp;" :item-render="{}">
            <template #default="{ data }">
              <vxe-input v-model="data.path" placeholder="请输入后端路由地址"></vxe-input>
            </template>
          </vxe-form-item>
          <vxe-form-item field="group" title="&nbsp;分&nbsp;&nbsp;组&nbsp;" :item-render="{}">
            <template #default="{ data }">
              <vxe-input v-model="data.group" placeholder="请输入后端路由分组名"></vxe-input>
            </template>
          </vxe-form-item>
          <vxe-form-item field="mid" title="&nbsp;父&nbsp;&nbsp;级&nbsp;" :item-render="{}">
            <template #default="{ data }">
              <vxe-select v-model="data.mid" transfer>
                <vxe-option :value="0" selected label="无"></vxe-option>
                <vxe-option v-for="item in menus" :key="item.id" :value="item.id" :label="item.name">
                </vxe-option>
              </vxe-select>
            </template>
          </vxe-form-item>

          <vxe-form-item field="type" title="方式" :span="12" :title-suffix="{
            useHTML: true,
            content: 'get post delete put...',
            icon: 'vxe-icon-info-circle-fill',
          }" :item-render="{}">
            <template #default="{ data }">
              <vxe-select v-model="data.type" transfer>
                <vxe-option v-for="item in typelist" :key="item" :value="item" :label="item">
                  {{ item }}
                </vxe-option>
              </vxe-select>
            </template>
          </vxe-form-item>
          <vxe-form-item :span="12"> </vxe-form-item>

          <vxe-form-item title="Other information" title-align="left" :title-width="200" :span="24" :title-prefix="{
            message: '请填写必填项',
            icon: 'vxe-icon-info-circle-fill',
          }"></vxe-form-item>
          <vxe-form-item field="status" title="&nbsp;状&nbsp;&nbsp;态&nbsp;" :item-render="{}">
            <template #default="{ data }">
              <vxe-switch v-model="data.status" open-label="启用" :open-value="1" close-label="禁用" :close-value="0">
              </vxe-switch>
            </template>
          </vxe-form-item>
          <vxe-form-item field="cross_domain" title="&nbsp;跨&nbsp;&nbsp;域&nbsp;" :item-render="{}">
            <template #default="{ data }">
              <vxe-switch v-model="data.cross_domain" open-label="允许" :open-value="1" close-label="禁止" :close-value="0">
              </vxe-switch>
            </template>
          </vxe-form-item>
          <vxe-form-item field="origin" title="域&nbsp;名" :item-render="{}">
            <template #default="{ data }">
              <vxe-input v-model="data.origin" placeholder="请输入允许跨域的域名"></vxe-input>
            </template>
          </vxe-form-item>
          <vxe-form-item field="credentials" title="跨域session共享" :item-render="{}">
            <template #default="{ data }">
              <vxe-switch v-model="data.credentials" open-label="允许" :open-value="1" close-label="禁止" :close-value="0">
              </vxe-switch>
            </template>
          </vxe-form-item>
          <vxe-form-item field="max_age" title="生命周期" :item-render="{}">
            <template #default="{ data }">
              <vxe-input v-model="data.max_age" placeholder="请输入生命周期"></vxe-input>
            </template>
          </vxe-form-item>
          <vxe-form-item field="desc" title="描述" :span="24" :item-render="{}" :title-suffix="{
            message: '描述信息，可选填',
            icon: 'vxe-icon-question-circle-fill',
          }">
            <template #default="{ data }">
              <vxe-textarea v-model="data.desc" :autosize="{ minRows: 2, maxRows: 4 }"></vxe-textarea>
            </template>
          </vxe-form-item>
          <vxe-form-item align="center" title-align="left" :span="24">
            <template #default>
              <vxe-button type="submit">提交</vxe-button>
              <vxe-button @click="reset">重置</vxe-button>
            </template>
          </vxe-form-item>
        </vxe-form>
      </template>
    </vxe-modal>
  </div>
</template>
<script>
import {
  routeList,
  routeAdd,
  getAllMenu,
  routeUpdate,
  routeDelete,
} from "@/requests/api";
import { VXETable } from "vxe-table";
import { ref } from 'vue'
const xGrid = ref()
import XEUtils from "xe-utils";
export default {
  name: "",
  mounted() {
    console.log(this.$refs.xGrid);
  },
  created() {
    getAllMenu({})
      .then((res) => {
        this.menus = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  data() {
    return {
      selectRow: true,
      menus: [],
      showEdit: false,
      loadding: false,
      formData: {
        name: "",
        path: "",
        id: "",
        type: "get",
        mid: 0,
        status: 0,
        aid: 0,
        controller: "",
        methods: "",
        group: "",
        origin: "*",
        credentials: "",
        max_age: 300,
        cross_domain: "",
      },
      typelist: ["get", "post", "put", "delete"],
      formRules: {
        name: [
          { required: true, message: "请输入名称" },
          { min: 2, max: 6, message: "长度在 2 到 6 个字符" },
        ],
      },
      gridOptions: {
        border: true,
        showHeaderOverflow: true,
        showOverflow: true,
        keepSource: true,
        id: "menu_list_table",
        height: 700,
        rowConfig: {
          keyField: "id",
          isHover: true,
        },
        columnConfig: {
          resizable: true,
        },
        customConfig: {
          storage: true,
          checkMethod({ column }) {
            if (["create_time", "update_time"].includes(column.field)) {
              return false;
            }
            return true;
          },
        },
        printConfig: {
          columns: [
            { field: "name" },
            { field: "path" },
            { field: "link" },
            { field: "pid" },
            { field: "icon" },
            { field: "status" },
            { field: "order" },
            { field: "desc" },
          ],
        },
        sortConfig: {
          trigger: "cell",
          remote: true,
        },
        filterConfig: {
          remote: true,
        },
        pagerConfig: {
          pageSize: 10,
          pageSizes: [5, 10, 15, 20, 50, 100, 200, 500, 1000],
        },
        formConfig: {
          titleWidth: 100,
          titleAlign: "right",
          items: [
            {
              field: "name",
              title: "名称",
              titlePrefix: {
                message: "菜单名称检索",
                icon: "vxe-icon-question-circle-fill",
              },
              itemRender: {
                name: "$input",
                props: { placeholder: "请输入名称" },
              },
            },
            {
              field: "path",
              title: "路由",
              itemRender: {
                name: "$input",
                props: { placeholder: "请输入路由地址" },
              },
            },

            {
              align: "center",
              // collapseNode: true,
              itemRender: {
                name: "$buttons",
                children: [
                  {
                    props: {
                      type: "submit",
                      content: "搜索",
                      status: "primary",
                    },
                  },
                  { props: { type: "reset", content: "清空" } },
                ],
              },
            },
          ],
        },
        toolbarConfig: {
          slots: {
            buttons: "toolbar_buttons",
          },
          refresh: true,
          import: true,
          export: true,
          print: true,
          zoom: true,
          custom: true,
        },
        proxyConfig: {
          seq: true, // 启用动态序号代理，每一页的序号会根据当前页数变化
          sort: true, // 启用排序代理，当点击排序时会自动触发 query 行为
          filter: true, // 启用筛选代理，当点击筛选时会自动触发 query 行为
          form: true, // 启用表单代理，当点击表单提交按钮时会自动触发 reload 行为
          // 对应响应结果 { result: [], page: { total: 100 } }
          props: {
            result: "data", // 配置响应结果列表字段
            total: "total", // 配置响应结果总页数字段
          },
          // 只接收Promise，具体实现自由发挥
          ajax: {
            // 当点击工具栏查询按钮或者手动提交指令 query或reload 时会被触发
            query: ({ page, sorts, filters, form }) => {
              const queryParams = Object.assign({}, form);
              // 处理排序条件
              const firstSort = sorts[0];
              if (firstSort) {
                queryParams.sort = firstSort.field;
                queryParams.order = firstSort.order;
              }
              // 处理筛选条件
              filters.forEach(({ field, values }) => {
                queryParams[field] = values.join(",");
              });
              queryParams.page = page.currentPage;
              queryParams.limit = page.pageSize;
              return routeList(queryParams);
            },
            // 当点击工具栏删除按钮或者手动提交指令 delete 时会被触发
            delete: ({ body }) => {
              console.log(body);

              return;
            },
            // 当点击工具栏保存按钮或者手动提交指令 save 时会被触发
            save: ({ body }) => {
              console.log(body);
              return;
            },
            edit: ({ id }) => {
              console.log(id);
            },
          },
        },
        columns: [
          { type: "checkbox", title: "ID", width: 66 },
          {
            field: "name",
            width: 80,
            sortable: false,
            title: "路由名",
            titlePrefix: { message: "后台页面菜单显示的汉字" },
          },
          {
            field: "group",
            width: 80,
            title: "分组名",
            sortable: false,
            titlePrefix: {
              useHTML: true,
              content: "对路由分组的组名",
            },
          },
          {
            field: "path",
            title: "路由地址",
            width: 90,
            sortable: false,
            titlePrefix: {
              useHTML: true,
              content: "前端访问的路由地址",
            },
          },
          {
            field: "controller",
            title: "控制器",
            sortable: false,
            titlePrefix: {
              useHTML: true,
              content: "路由对应的控制器",
            },
          },
          {
            field: "method",
            title: "方法",
            sortable: false,
            titlePrefix: {
              useHTML: true,
              content: "路由对应的方法",
            },
          },
          {
            field: "origin",
            title: "跨域域名",
            default: "*",
            sortable: false,
            titlePrefix: {
              useHTML: true,
              content: "允许跨域访问的域名，默认*",
            },
          },
          {
            field: "aid",
            title: "appid",
            sortable: false,
            titlePrefix: {
              useHTML: true,
              content: "路由所属的app",
            },
          },
          {
            field: "mid",
            title: "菜单id",
            sortable: false,
            titlePrefix: {
              useHTML: true,
              content: "路由关联的菜单",
            },
          },
          {
            field: "create_time",
            title: "创建时间",
            visible: false,
            sortable: true,
            formatter({ cellValue }) {
              return XEUtils.toDateString(cellValue, "yyyy-MM-dd HH:ss:mm");
            },
          },
          {
            field: "update_time",
            title: "最后修改时间",
            visible: false,
            sortable: true,
            formatter({ cellValue }) {
              return XEUtils.toDateString(cellValue, "yyyy-MM-dd HH:ss:mm");
            },
          },
          {
            field: "type",
            title: "请求类型",
            visible: true,
            titlePrefix: {
              useHTML: true,
              content: "get post put delete option",
            },
          },
          {
            field: "id",
            title: "操作",
            type: "html",
            slots: {
              default: "option_btn",
            },
          },
          {
            field: "status",
            title: "是否显示",
            type: "html",
            slots: {
              default: "status_switch",
            },
          },
        ],
        importConfig: {
          remote: true,
          types: ["xlsx"],
          modes: ["insert"],
          // 自定义服务端导入
          importMethod({ file }) {
            const $grid = xGrid.value;
            const formBody = new FormData();
            formBody.append("file", file);
            return fetch({})
              .then((response) => response.json())
              .then((data) => {
                VXETable.modal.message({
                  content: `成功导入 ${data.result.insertRows} 条记录！`,
                  status: "success",
                });
                // 导入完成，刷新表格
                $grid.commitProxy("query");
              })
              .catch(() => {
                VXETable.modal.message({
                  content: "导入失败，请检查数据是否正确！",
                  status: "error",
                });
              });
          },
        },
        exportConfig: {
          remote: true,
          types: ["xlsx"],
          modes: ["current", "selected", "all"],
          // 自定义服务端导出
          exportMethod({ options }) {
            const $grid = xGrid.value;
            const proxyInfo = $grid.getProxyInfo();
            // 传给服务端的参数
            const body = {
              filename: options.filename,
              sheetName: options.sheetName,
              isHeader: options.isHeader,
              original: options.original,
              mode: options.mode,
              pager: proxyInfo ? proxyInfo.pager : null,
              ids:
                options.mode === "selected"
                  ? options.data.map((item) => item.id)
                  : [],
              fields: options.columns.map((column) => {
                return {
                  field: column.field,
                  title: column.title,
                };
              }),
            };
            // 开始服务端导出
            return fetch({ body })
              .then((response) => response.json())
              .then((data) => {
                if (data.id) {
                  VXETable.modal.message({
                    content: "导出成功，开始下载",
                    status: "success",
                  });
                  // 读取路径，请求文件
                  fetch({}).then((response) => {
                    response.blob().then((blob) => {
                      // 开始下载
                      VXETable.saveFile({
                        filename: "导出数据",
                        type: "xlsx",
                        content: blob,
                      });
                    });
                  });
                }
              })
              .catch(() => {
                VXETable.modal.message({
                  content: "导出失败！",
                  status: "error",
                });
              });
          },
        },
        checkboxConfig: {
          labelField: "id",
          reserve: true,
          highlight: true,
          range: true,
        },
      },
    };
  },
  methods: {
    add() {
      this.selectRow = false;
      this.showEdit = true;
    },
    edit(row) {
      this.selectRow = true;
      this.formData = row;
      this.showEdit = true;
      delete this.formData.create_time;
      delete this.formData.update_time;
    },
    removeOne(row) {
      VXETable.modal
        .confirm({
          content: "删除后无法恢复！确定要删除？",
        })
        .then((res) => {
          if (res == "confirm") {
            this.notifyDelete([row.id]);

          } else {
            VXETable.modal.message({
              status: "info",
              content: "操作取消",
              top: 80,
            });
          }
        });
    },
    removeMany() {
      VXETable.modal
        .confirm({
          content: "删除后无法恢复！确定要删除多条记录？",
        })
        .then((res) => {
          if (res == "confirm") {
            this.notifyDelete(
              this.$refs.xGrid.getCheckboxRecords().map((x) => {
                return x.id;
              })
            );

          } else {
            VXETable.modal.message({
              status: "info",
              content: "操作取消",
              top: 80,
            });
          }
        });
    },
    notifyDelete(ids) {
      routeDelete({
        id: ids,
      }).then(() => {
        VXETable.modal.message({
          status: "success",
          content: "操作成功！",
          top: 80,
        });
        this.$refs.xGrid.commitProxy('query');
      });
    },
    change(e, row) {
      console.log(e, row);
      // menuUpdate({
      //   id: row.id,
      //   status: e.target.checked ? 1 : 0,
      // })
      //   .then((res) => {
      //     console.log(res);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
    reset() {
      var default_Data = {
        name: "",
        path: "",
        link: "",
        pid: 0,
        icon: "fa-list-squares",
        order: 1,
        status: 1,
      };
      this.formData = default_Data;
    },
    submitEvent() {
      if (this.selectRow) {
        routeUpdate(this.formData).then(() => {
          this.$refs.xGrid.commitProxy('query');
          this.showEdit = false;
          VXETable.modal.message({
            status: "success",
            content: "操作成功！",
            top: 80,
          });
        });
      } else {
        routeAdd(this.formData)
          .then(() => {
            this.showEdit = false;
            this.$refs.xGrid.commitProxy('query');
            VXETable.modal.message({
              status: "success",
              content: "操作成功！",
              top: 80,
            });
          })
          .catch((err) => {
            console.log(err);
          });

      }
      this.reset();
    },
    visibleMethod() { },
  },
};
</script>
<style>
/* .vxe-table--header {
    background-color: green;
    color: #fff;
  } */
</style>
